<template>
  <div>
    <div v-if="loaded">
      <h2 class="mb-8 mt-4 page-title">Variance Report</h2>
      <v-row justify="start" class="mt-6">
        <v-col>
          <custom-date-range-filter :events="countDates" @change="dates = $event" />
        </v-col>
        <v-col>
          <v-radio-group v-model="xAxisValue" row>
            <v-radio label="Over time" value="time"></v-radio>
            <v-radio label="Per item" value="item"></v-radio>
          </v-radio-group>
        </v-col>
        <v-spacer />
        <v-col>
          <v-autocomplete
            v-model="filteredItems"
            label="Filter by Item"
            :items="items"
            item-text="name"
            outlined
            multiple
            return-object
            clearable
          />
        </v-col>
      </v-row>
      <div id="graphTable" class="mt-6">
        <v-row justify="start">
          <v-col cols="12">
            <v-apex-charts
              :type="chartOptions.chart.type"
              :options="chartOptions"
              :series="graphData"
              height="500"
            />
          </v-col>
        </v-row>
        <v-card class="table-card mx-2 ml-10">
          <v-card-subtitle v-if="dates">
            The data below reflects the total variance throughout the selected date
            range: {{ dates.join(" ~ ") }}
          </v-card-subtitle>
          <v-data-table
            :items-per-page="-1"
            :headers="headers"
            :items="tableData"
            sort-by="unitVariance"
            :sort-desc="true"
          >
            <template v-slot:[`item.name`]="{ item }">
              {{ getInvItemName(item.itemID) }}
            </template>
            <template v-slot:[`item.dollarVariance`]="{ item }">
              {{ "$ " + item.dollarVariance.toFixed(2) }}
            </template>
            <template v-slot:[`item.showOnGraph`]="{ item }">
              <v-simple-checkbox
                v-if="getInvItem(item.itemID)"
                :value="itemIsFiltered(item)"
                @click="filterItemsFromTable(item)"
              />
            </template>
            <template v-slot:[`footer.page-text`]>
              <vue-json-to-csv
                :json-data="tableData"
                csv-title="Inventory Variance Report"
              >
                <v-btn icon small><v-icon small>mdi-download</v-icon></v-btn>
              </vue-json-to-csv>
            </template>
          </v-data-table>
        </v-card>
      </div>
    </div>
    <div v-else>
      <v-progress-circular indeterminate color="primary" />
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts"
import CustomDateRangeFilter from "../../shared/forms/datetime/CustomDateRangeFilter.vue"
import getInventoryItemsLog from "@/mixins/getInventoryItemsLog.js"
import { mapState } from "vuex"
import VueJsonToCsv from "vue-json-to-csv"
import moment from "moment"

export default {
  name: "variance-report",
  components: {
    "v-apex-charts": VueApexCharts,
    CustomDateRangeFilter,
    "vue-json-to-csv": VueJsonToCsv,
  },
  mixins: [getInventoryItemsLog],
  data() {
    return {
      loaded: false,
      availableReports: ["Inventory Items", "Menu Items"],
      headers: [
        { text: "Item Name", value: "name" },
        { text: "Unit Variance", value: "unitVariance" },
        { text: "Dollar Variance", value: "dollarVariance" },
        { text: "Show on Graph", value: "showOnGraph" },
      ],
      dates: [],
      receivings: [],
      inventoryItemsLog: [],
      filteredItems: [],
      xAxisValue: "time",
    }
  },
  computed: {
    ...mapState(["counts", "writeoffs", "items", "receiving"]),
    varianceData() {
      let variances = []
      this.counts.forEach(count => {
        if (
          !this.dates.length ||
          moment(count.date).isBetween(this.dates[0], this.dates[1])
        ) {
          count.itemsCounted.forEach(itemCounted => {
            variances.push({
              date: count.date,
              itemID: itemCounted.itemID,
              actualVal: parseFloat(itemCounted.quantity),
              actualValCostMoney:
                parseFloat(itemCounted.costMoney.amount) * itemCounted.quantity,
              theoreticalVal: parseFloat(itemCounted.prevQuantity),
              theoreticalValCostMoney:
                parseFloat(itemCounted.costMoney.amount) * itemCounted.prevQuantity,
            })
          })
        }
      })
      let variancesGroupedByDate = []
      this.counts.forEach(count => {
        variancesGroupedByDate.push(
          variances.filter(data => data.date == count.date)
        )
      })
      return variancesGroupedByDate
    },
    graphData() {
      let data = []
      let graphData
      if (this.xAxisValue == "time") {
        graphData = this.lineGraphData
      } else {
        graphData = this.barGraphData
      }
      data = [
        {
          name: "Actual Values",
          data: graphData.map(point => point.actualVal),
        },
        {
          name: "Theoretical Values",
          data: graphData.map(point => point.theoreticalVal),
        },
      ]
      return data
    },
    lineGraphData() {
      let filteredItemIds = this.filteredItems.map(item => item.itemID)
      let lineGraphData = this.varianceData
      if (filteredItemIds.length > 0) {
        lineGraphData = this.filterDataForLineGraph(
          lineGraphData,
          filteredItemIds
        ).filter(data => data.length)
      }
      //check for itemIDS here and remove from data to be passed
      let dateObj = {}
      let dataArray = []
      for (let i = 0; i < lineGraphData.length; i++) {
        dateObj = {}
        dateObj.date = lineGraphData[i][0]?.date
        dateObj.actualVal = lineGraphData[i].reduce((sum, obj) => {
          return sum + obj.actualVal
        }, 0)
        dateObj.theoreticalVal = lineGraphData[i].reduce((sum, obj) => {
          return sum + obj.theoreticalVal
        }, 0)
        dataArray.push(dateObj)
      }
      return dataArray
    },
    barGraphData() {
      let barGraphData = []
      let mostRecentCountEntry = {}
      this.items.forEach(item => {
        mostRecentCountEntry = this.counts
          .filter(
            count =>
              !this.dates.length ||
              moment(count.date).isBetween(this.dates[0], this.dates[1])
          )
          .map(count => count.itemsCounted)
          .flat()
          .find(countedItem => countedItem.itemID == item.itemID)
        if (mostRecentCountEntry) {
          barGraphData.push({
            itemID: item.itemID,
            name: item.name,
            actualVal: mostRecentCountEntry?.quantity,
            theoreticalVal: mostRecentCountEntry?.prevQuantity,
          })
        }
      })
      if (this.filteredItems.length > 0) {
        barGraphData = barGraphData.filter(data =>
          this.filteredItems.map(item => item.itemID).includes(data.itemID)
        )
      }
      return barGraphData
    },
    tableData() {
      let data = this.varianceData
      let itemVariance = data.flat()
      let logEntry = {}
      // calculate actualValCostMoney and theoreticalValCostMoney for each data point
      itemVariance.forEach(dataPoint => {
        logEntry = this.inventoryItemsLog.find(
          entry =>
            entry.date == dataPoint.date && entry && entry.itemId == dataPoint.itemID
        )
        if (logEntry !== undefined) {
          dataPoint.theoreticalValCostMoney =
            parseFloat(logEntry.costMoney.amount) *
            parseFloat(dataPoint.theoreticalVal)
        }
      })
      // reduce data to one entry per itemID
      let formattedData = []
      itemVariance.forEach(dataPoint => {
        let preexistingObj = formattedData.find(
          entry => entry.itemID == dataPoint.itemID
        )
        if (preexistingObj) {
          preexistingObj.actualValCostMoney += dataPoint.actualValCostMoney
          preexistingObj.theoreticalValCostMoney += dataPoint.theoreticalValCostMoney
          preexistingObj.actualVal += dataPoint.actualVal
          preexistingObj.theoreticalVal += dataPoint.theoreticalVal
        } else {
          formattedData.push(dataPoint)
        }
      })
      // format data for table
      return formattedData.map(data => {
        return {
          itemID: data.itemID,
          unitVariance: Math.abs(data.actualVal - data.theoreticalVal),
          dollarVariance: Math.abs(
            data.actualValCostMoney - data.theoreticalValCostMoney
          ),
        }
      })
    },
    countDates() {
      return this.counts != null ? this.counts.map(count => count.date) : []
    },
    chartOptions() {
      let opts = {
        dataLabels: {
          enabled: false,
        },
        legend: {
          itemMargin: {
            horizontal: 5,
            vertical: 25,
          },
        },
        xaxis: {
          labels: {
            show: true,
            offsetY: 5,
            offsetX: 5,
          },
        },
        tooltip: {
          theme: this.$vuetify.theme.dark ? "dark" : "light",
        },
      }
      if (this.xAxisValue == "time") {
        const independentValues = this.lineGraphData.map(data => data.date)
        opts = {
          dataLabels: opts.dataLabels,
          legend: opts.legend,
          chart: {
            type: "area",
          },
          stroke: {
            curve: "smooth",
          },
          xaxis: {
            type: "datetime",
            labels: {
              ...opts.xaxis.labels,
              format: "dd MMM yyyy",
            },
            tickAmount: independentValues.length,
            categories: independentValues.sort(),
          },
          markers: {
            size: 5,
          },
          tooltip: {
            x: {
              format: "dd/MM/yy",
            },
            theme: opts.tooltip.theme,
          },
        }
      } else {
        opts = {
          ...opts,
          chart: {
            type: "bar",
            columnWidth: "55%",
          },
          xaxis: {
            type: "category",
            labels: {
              ...opts.xaxis.labels,
            },
            tickAmount: this.barGraphData.length,
            categories: this.barGraphData.map(point => point.name),
          },
          axisTicks: {
            show: true,
          },
          plotOptions: {
            bar: {
              horizontal: false,
              dataLabels: {
                position: "top",
                enabled: false,
              },
              stroke: {
                show: true,
                width: 2,
                colors: ["transparent"],
              },
            },
          },
          tooltip: {
            theme: opts.tooltip.theme,
          },
        }
      }
      return opts
    },
  },
  async mounted() {
    this.inventoryItemsLog = await this.getInventoryItemsLog()
    if (this.counts.length) {
      this.counts = this.counts.sort(function (a, b) {
        return new Date(a.date) - new Date(b.date)
      })
    }

    this.loaded = true
  },
  methods: {
    filterDataForLineGraph(unorganizedData, itemIds) {
      for (let i = 0; i < unorganizedData.length; i++) {
        for (let j = 0; j < unorganizedData[i].length; j++) {
          if (!itemIds.includes(unorganizedData[i][j].itemID)) {
            unorganizedData[i].splice(j, 1)
            j--
          }
        }
      }
      return unorganizedData
    },
    getInvItemName(itemID) {
      const invItem = this.getInvItem(itemID)
      return invItem ? invItem.name : "(Deleted Item)"
    },
    getInvItem(itemID) {
      return this.items.find(item => item.itemID == itemID)
    },
    filterItemsFromTable(item) {
      let indexOfItem = this.filteredItems.findIndex(
        filteredItem => item && filteredItem.itemID == item.itemID
      )
      if (indexOfItem != -1) {
        this.filteredItems.splice(indexOfItem, 1)
      } else {
        this.filteredItems.push(
          this.items.find(filteredItem => filteredItem.itemID == item.itemID)
        )
      }
    },
    itemIsFiltered(item) {
      return (
        this.filteredItems.findIndex(
          filteredItem => filteredItem.itemID == item.itemID
        ) != -1
      )
    },
  },
}
</script>
