import { mapState } from "vuex"
import { getDocs } from "firebase/firestore"

export default {
  computed: {
    ...mapState(["firebaseGetters"]),
  },
  methods: {
    async getInventoryItemsLog() {
      const querySnapshot = await getDocs(this.firebaseGetters.inventoryitemslogRef)
      return querySnapshot.docs
        .map(entry => entry.data())
        .filter(entry => !entry.isDeleted)
    },
  },
}
